@switch (statusToDisplay()) {
  @case (UserAccountStatus.Blocked) {
    <mpk-user-blocked-icon
      class="blocked-status-icon"
      matTooltip="Der Benutzer ist gesperrt."
      matTooltipPosition="above"
    />
  }

  @case (UserAccountStatus.Inactive) {
    <mpk-user-inactive-icon matTooltip="Der Benutzer ist noch inaktiv." matTooltipPosition="above" />
  }
}
