import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UserBlockedIconComponent } from '../user-blocked-icon/user-blocked-icon.component';
import { UserInactiveIconComponent } from '../user-inactive-icon/user-inactive-icon.component';

export interface UserAccountStatusInfo {
  isBlocked: boolean;
  isActivated: boolean;
}

enum UserAccountStatus {
  Blocked = 'Blocked',
  Inactive = 'Inactive',
}

@Component({
  selector: 'mpk-user-account-status-icon',
  standalone: true,
  templateUrl: './user-account-status-icon.component.html',
  styleUrl: './user-account-status-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTooltipModule, UserBlockedIconComponent, UserInactiveIconComponent],
})
export class UserAccountStatusIconComponent {
  readonly userAccountStatus: InputSignal<UserAccountStatusInfo> = input.required<UserAccountStatusInfo>();

  protected readonly statusToDisplay: Signal<UserAccountStatus | null> = computed(() => this.getStatusToDisplay());

  protected readonly UserAccountStatus = UserAccountStatus;

  private getStatusToDisplay(): UserAccountStatus | null {
    const { isActivated, isBlocked }: UserAccountStatusInfo = this.userAccountStatus();

    if (isBlocked) {
      return UserAccountStatus.Blocked;
    } else if (!isActivated) {
      return UserAccountStatus.Inactive;
    } else {
      return null;
    }
  }
}
