import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  InputSignal,
  OutputEmitterRef,
  TemplateRef,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';

import { TemplateContext } from '@core/shared/util';
import { AvatarComponent, OverviewModule, SpinnerComponent } from '@core/ui';
import { IdentityUserListItem } from '@mp/kernel/users/domain';
import { UserImagePipe } from '@mp/kernel/users/util';

import { UserAccountStatusIconComponent } from '../user-account-status-icon/user-account-status-icon.component';

@Component({
  selector: 'mpk-users-list-view',
  standalone: true,
  templateUrl: './users-list-view.component.html',
  styleUrls: ['./users-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    NgTemplateOutlet,
    ScrollingModule,

    MatButtonModule,
    MatIconModule,
    MatMenuModule,

    AvatarComponent,
    OverviewModule,
    SpinnerComponent,
    UserImagePipe,
    UserAccountStatusIconComponent,
  ],
})
export class UsersListViewComponent {
  readonly users: InputSignal<IdentityUserListItem[]> = input.required<IdentityUserListItem[]>();

  readonly usersLoaded: InputSignal<boolean> = input.required<boolean>();

  readonly loadUsers: OutputEmitterRef<string | undefined> = output<string | undefined>();

  @ContentChild(TemplateRef)
  userDetailsTemplate!: TemplateRef<TemplateContext<IdentityUserListItem>>;

  onSearch(searchTerm: string | undefined): void {
    this.loadUsers.emit(searchTerm);
  }

  trackByFn(_index: number, { userId }: IdentityUserListItem): string {
    return userId;
  }
}
